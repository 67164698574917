import axios from "axios";

// axios.defaults.headers["X-CSRF-TOKEN"] = window.csrf;

class Api {
    base_url = "";
    headers = {};
    protocol = window.location.protocol;
    hostname =
        (window.location.hostname.indexOf("www.") && window.location.hostname) ||
        window.location.hostname.replace("www.", "");
    // hostname = "smartschool.edigitalnepal.edu.np";
    // hostname = "pabson.org.np";
    // hostname = "lna.edu.np";
    // hostname = "spa.edu.np";
    // hostname = "aitm.edu.np";
    // hostname = "sabalnepal.org.np";
    // hostname = "127.0.0.1";
    // hostname = "sgss.edu.np";
    // hostname = "edigitalnepal.edu.np";
    // hostname = "prithwischool.edu.np";
    // hostname = "mmihs.edu.np";
    // hostname = "pentagon.edu.np";
    // hostname = "whitehouse.edu.np";
    // hostname = "whitehouseworldschool.edu.np";
    // hostname = "whitehouseschool.edu.np";
    // hostname = "mgentertainment.com.np";
    // hostname = "shreegaurishankar.edu.np";
    // hostname = "davnepal.edu.np";
    // hostname = "elitegrandschool.edu.np";
    // hostname = "gds.edu.np";
    // hostname = "schooladarsha.edu.np";
    // hostname = "rajendraphuyal.com.np";
    // hostname = "prarambhaacademy.edu.np";
    // hostname = "ankuranschool.edu.np";
    // hostname = "nobel.edu.np";
    // hostname = "elixir.edu.np";
    // hostname = "school.bluebird.edu.np";
    // hostname = "college.bluebird.edu.np";
    // hostname = "bluebird.edu.np";
    // hostname = "millsberry.edu.np";
    // hostname = "millsberry.com.np";
    // hostname = "patannist.edu.np";
    // hostname = "omegaschool.edu.np";
    // hostname = "omegacollege.edu.np";
    // hostname = "kathmandueuroschool.edu.np";
    // hostname = "soshgssurkhet.edu.np";
    // hostname = "soshgsbharatpur.edu.np";
    // hostname = "soshgsitahari.edu.np";
    // hostname = "soshgsgandaki.edu.np";
    // hostname = "soshgssanothimi.edu.np";
    // hostname = "soshgskavre.edu.np";
    // hostname = "soshgspokhara.edu.np";
    // hostname = "samt.edu.np";
    // hostname = "rsmv.edu.np";
    // hostname = "samc.edu.np";
    // hostname = "bsamv.edu.np";
    // hostname = "smsv.edu.np";
    // hostname = "sams.edu.np";
    // hostname = "samdharan.edu.np";
    // hostname = "sampokhara.edu.np";
    // hostname = "bgmsm.edu.np";
    // hostname = "srv.edu.np";
    // hostname = "prarambhaacademy.edu.np";
    // hostname = "hemsschool.edu.np";
    // hostname = "venus.edu.np";
    // hostname = "shwettara.edigitalnepal.edu.np";
    // hostname = "davnepal.edigitalnepal.edu.np";
    // hostname = "davnepal.edu.np";
    // hostname = "website.davnepal.edu.np";
    // hostname = "mrjlschool.edu.np";
    // hostname = "bktnist.edu.np";
    // hostname = "45.115.218.25";
    // hostname = "sxc.edu.np";
    // hostname = "loyola.sxc.edu.np";
    // hostname = "tu.sxc.edu.np";
    // hostname = "neb.sxc.edu.np";
    // hostname = "journal.sxc.edu.np";
    // hostname = "journal.khwopacollege.edu.np";
    // hostname = "khwopacollege.edu.np";
    // hostname = "jmssg.edu.np";
    // hostname = "ekhatanepal.com";
    // hostname = "bktnist.edigitalnepal.edu.np";
    // hostname = "davnepal.edigitalnepal.edu.np";
    // hostname = "xavier.edigitalnepal.edu.np";
    // hostname = "sxc.edigitalnepal.edu.np";
    // hostname = "demo3.edigitalnepal.edu.np";
    // hostname = "demo2.edigitalnepal.edu.np";
    // hostname = "camadcollege.edu.np";
    // hostname = "sgss.edu.np";
    // hostname = "nawajagritischool.edu.np";
    // hostname = "glaciercollege.edu.np";
    // hostname = "milestonecollege.edu.np";
    // hostname = "pentagon.edu.np";
    // hostname = "cao.p2.gov.np";
    // hostname = "eastpoleschool.edu.np";
    // hostname = "lfs.edu.np";
    // hostname = "grs.edu.np";
    // hostname = "spspokhara.edu.np";
    // hostname = "website.edigitalnepal.com.np";
    // hostname = "uniquecoms.com";
    // hostname = "demo.edigitalnepal.edu.np";
    // hostname = "demo.edigitalnepal.com.np";
    // hostname = "demo.edigitalnepal.edu.np";
    // hostname = "mgentertainment.com.np";2
    // hostname = "super@admin.com";
    // hostname = "bnks.edu.np";
    // hostname = "demo.edigitalnepal.edu.np";
    API_URL = this.hostname + "/api";
    // API_URL = this.hostname + ":8000/api";

    constructor() {
        // this.headers["Authorization"] = `Bearer ${
        //   process.env.REACT_APP_SCHOOL_AUTHORIZATION_TOKEN
        // }`;
        this.base_url = this.protocol + "//" + this.API_URL;
    }

    get(path, data) {
        const url = `${this.base_url}${path}`;
        return axios
            .get(url, { params: { client: this.hostname, data: data } })
            .then(function (response) {
                // console.log(response);
                return response.data;
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    post(path, data) {
        const url = `${this.base_url}${path}`;
        this.headers["Content-Type"] =
            "application/x-www-form-urlencoded;charset=UTF-8";

        return axios
            .post(url, {
                headers: this.headers,
                client: this.hostname,
                body: data,
            })
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                //console.log(error);
            });
    }

    create(path, data) {
        const url = `${this .base_url}${path}`;
        this.headers["Content-Type"] =
            "application/x-www-form-urlencoded;charset=UTF-8";

        return axios
            .post(url, {
                headers: this.headers,
                client: this.hostname,
                body: data,
            })
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                //console.log(error);
            });
    }
}

export default Api;