import React from "react";
import {Provider} from "react-redux";
import {createRoot} from 'react-dom/client';
import "./index.css";
import {ConnectedRouter} from "connected-react-router";
import configureStore, {history} from "./utils/configureStore";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import App from "./App";
import ScrollToTop from "./utils/ScrollToTop"; //reset page scroll to top when page changes
import ScrollTosTop from "react-scroll-to-top"; //onclick page up

// environment variables
require("dotenv").config();

const store = configureStore(/* provide initial state if any */);

// upgrade react 16 to react 18
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<ScrollToTop>
					<App/>
				</ScrollToTop>
				<ScrollTosTop smooth/>
			</ConnectedRouter>
		</Provider>
);

// ReactDOM.render(
// 	<Provider store={store}>
// 		<ConnectedRouter history={history}>
// 			<ScrollToTop>
// 				<App />
// 			</ScrollToTop>
// 			<ScrollTosTop smooth />
// 		</ConnectedRouter>
// 	</Provider>,
// 	document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
